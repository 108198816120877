import styles from "./styles.module.css";

const TemasDeHoy = (props) => {
	const SITE_EXTERNAL = 'site_external';
	const { title = "#HOY: " } = props;
	function getMenuLinkUrl(item) {
        if (item.slug) {
            return item.type === SITE_EXTERNAL ? item.slug : '/' + item.slug;
        } else {
            return '#'
        }
    }	

	return (
		<>
			{props.menu && props.menu.items && props.menu.items.length > 0 && (
				<div className={`${styles['content-parent']}`}>
					<h2 className={`${styles['tag-title']}`}>{title}</h2>
					<div className={`${styles["horizontal-scroll-wrapper"]}`}>
						{props.menu.items.map((element, id) => {
							const { name } = element;

							return (
									<div key={id} className={`${styles["content-li"]}`}>
										<a href={getMenuLinkUrl(element)}>
											<span>{name}</span>
										</a>
									</div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};

export default TemasDeHoy;

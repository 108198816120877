import { useState } from "react";
import styles from "./styles.module.css";

import ChevronDown from "@/icons/components/ChevronDown";
import ChevronUp from "@/icons/components/ChevronUp";

const Accordion = (props) => {
  const { item, hasChilds } = props;
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const showSubMenu = () => {
    if (!hasChilds) {
      return;
    }
    setSubMenuOpen(!subMenuOpen);
  };

  const SITE_EXTERNAL = "site_external";
  function getMenuLinkUrl(item) {
    if (item.slug) {
      return item.type === SITE_EXTERNAL ? item.slug : "/" + item.slug;
    } else {
      return "#";
    }
  }

  const getTarget = (item) => {
    return item.type === SITE_EXTERNAL ? "_blank" : "_self";
  };

  return (
    <>
      {hasChilds ? (
        <>
          <div className={styles["item"]}>
            <a href={getMenuLinkUrl(item)} target={getTarget(item)}>
              {item.name}
            </a>
            {subMenuOpen ? (
              <span onClick={showSubMenu}>
                <ChevronUp className={styles["chevron"]}/>
              </span>
            ) : (
              <span onClick={showSubMenu}>
                <ChevronDown className={styles["chevron"]}/>
              </span>
            )}
          </div>

          {subMenuOpen && (
            <ul className={styles["item-submenu"]}>
              {item.items &&
                item.items.map((subItem) => (
                  <li key={subItem._id} className={styles["item"]}>
                    <a
                      href={getMenuLinkUrl(subItem)}
                      target={getTarget(subItem)}
                    >
                      {subItem.name}
                    </a>
                  </li>
                ))}
            </ul>
          )}
        </>
      ) : (
        <a
          className={styles["item"]}
          href={getMenuLinkUrl(item)}
          target={getTarget(item)}
        >
          {item.name}
        </a>
      )}
    </>
  );
};

export default Accordion;

import React, { useState, useEffect } from "react";
import Image from "../Image/new";
import SidePanel from "./SidePanel";
import styles from "./styles.module.css";
import TemasDeHoy from "./TemasDeHoy";
const Header = (props) => {
	const urlSearchHeader = '/busqueda#gsc.tab=0';

	const documentHeight = () => {
		if (typeof window !== "undefined") {
			return window.document.body.scrollHeight
		}
	};

	const urlWeatherServices = "/servicios/clima";
	const TINY_WEATHER_API_URL = "/api/clima/tiny";

	const [weather, setWeather] = useState()

	const getTinyWeather = async () => {
		try {
			const res = await fetch(TINY_WEATHER_API_URL);
			const result = await res.json();
			if (result && result.code === 200) {
				setWeather(result.data);
			}
		} catch (err) { }
	}

	const [visible, setVisible] = useState(true);

	useEffect(() => {
		getTinyWeather()
		
		if (typeof window != 'undefined') {
			
			let lastScrollTop = 0;

			const handleScroll = () => {

				const valueScrollY = window.scrollY;

				if (valueScrollY > lastScrollTop) {
						
					setVisible(false)
						
				}

				if (valueScrollY < lastScrollTop) {
						
					setVisible(true)
				}

				lastScrollTop = valueScrollY <= 0 ? 0 : valueScrollY;

			}

			window.addEventListener("scroll", handleScroll);

			return (() => {
				window.removeEventListener("scroll", handleScroll);
			})
		}
		
	}, [])

	const measurementHeight = documentHeight();

	let menuSidebar = props.menues['el-litoral-web'] ? props.menues['el-litoral-web'].item : [];
	let menuHeader = props.menues['el-litoral-temas'] ? props.menues['el-litoral-temas'].item : [];

	const [openSideMenu, setOpenSideMenu] = useState(false);
	const isOpen = (open) => {
		setOpenSideMenu(!open);
		props.isOpenSidePanel(!open);
	};

  const classVisible = !visible ? "hidden" : "visible";
  
	return (
		<>
			<div className={`${styles["header-container"]} ${styles[classVisible]}`}>
				{
					props.proviImage && 
						<Image
							src={props.proviImage}
							width={25}
							height={25}
							className={`pie_foto_uminterior`}
						/>
				}

				<nav className={`${styles["header"]}`}>
					<div className={`${styles["nav-bar-row"]} ${styles['margin-header']}`}>
						<div className={`${styles["nav-bar-menu-container"]}`} >
							<div
								onClick={() => isOpen(openSideMenu)}
								className={`${styles["nav-button"]}`}
							>
								<div className={`${styles['menu-desktop']}`}>
									+ SECCIONES
								</div>

								<Image
									src={"/img/navbarMenu.png"}
									alt={'menú'}
									width={25}
									height={25}
									className={`${styles['menu-mobile']}`}
								/>
							</div>
						</div>
						<a href="/" className={`${styles["header-logo"]}`}>
							<div className={`${styles["logo"]}`}>
								<Image
									src={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/header_logoellitoral.svg`}
									alt="El Litoral"
									className={`${styles["logo"]}`}
								/>
							</div>
						</a>
						<div className={`${styles["nav-bar"]}`}>
							{weather &&
								<a href={urlWeatherServices} className={`${styles["weather-container"]}`}>
									<Image
										src={`/img/clima/${weather.icon.padStart(2, "0")}.png`}
										alt={weather.conditions}
										className={`${styles["weather-img"]}`}
										width={32}
										height={32}
									/>
									<span className={`${styles['weather-text']}`}>{weather.temp && `${weather.temp}°`}</span>
								</a>
							}
							{/* <a href="/" className={`${styles['header-link']}`}>
								<span>INGRESAR</span>
							</a> */}
							<a href={urlSearchHeader} className={`${styles['header-link']}`}>
								<span>BUSCAR</span>
							</a>
						</div>
					</div>
				</nav>
				{openSideMenu ? (
					<SidePanel
						menu={menuSidebar}
						isOpen={isOpen}
						measurementHeight={measurementHeight}
						urlSearchHeader={urlSearchHeader}
					/>
				) : null}
			</div>

			<div className={`${styles["nav-bar-tdh"]}`}>
				<div className={`${styles['margin-header']}`}>
					<TemasDeHoy menu={menuHeader} />
				</div>
			</div>
		</>
	);
};

export default Header;

import { 
	Search,
	Phone,
	Close,
	Facebook,
	Twitter,
	Instagram,
	YouTube,
	Whatssap
 } from '@/icons/index'

import Accordion from "./Accordion";
import styles from "./styles.module.css";

const SidePanel = (props) => {
	const { menu, isOpen, urlSearchHeader, measurementHeight } = props;

	const opacity = {
		width: "100%",
		height: `${measurementHeight}px`,
		position: "absolute",
		backgroundColor: "rgba(118, 118, 118, 0.576)",
		zIndex: "100",
		top: "0",
		transition: "transform 3s ease-in-out"
	}

	return (
		<div style={opacity} onClick={isOpen}>
			<nav className={styles["new-sidepanel"]} onClick={(e) => e.stopPropagation()} >
				<div className={styles["new-sidepanel-head"]}>
						<a href="/">
							<h5>EL LITORAL</h5>
						</a>
					<div
						className={styles["new-sidepanel-head-container-icons"]}
					>
						<a href={urlSearchHeader}>
							<Search 
								className={`${styles['usability-icon']}`}
							/>
						</a>
						<a href={`https://servicios.ellitoral.com/seccion/contacto_diario`} target="_blank" rel='noreferrer noopener'>
							<Phone
								className={`${styles['usability-icon']}`}
							/>
						</a>
						<div
							title={"Close"}
							onClick={isOpen}
						>
							<Close
								className={`${styles['close-usability-icon']}`}
							/>
						</div>
					</div>
				</div>

				<ul className={styles["new-sidepanel-menu"]}>
					{menu &&
						menu.items &&
						menu.items.map((item) => {
							const hasChilds = (item.items && item.items.length > 0);
							return (
								<li key={item._id}>
									<Accordion hasChilds={hasChilds} item={item} />
								</li>
							);
						})}
				</ul>
				<div className={`${styles["new-sidepanel-social"]}`}>
					<a href="https://www.facebook.com/ellitoralcom">
						<div
							className={`${styles["new-sidepanel-social-link"]} ${styles["color-facebook"]}`}
						>
								<Facebook
									className={`${styles["icon"]} ${styles['facebook-icon']}`}
								/>
							
						</div>
					</a>
					
					<a href="https://twitter.com/ellitoral">
						<div
							className={`${styles["new-sidepanel-social-link"]} ${styles["color-twitter"]}`}
						>
								<Twitter
									className={`${styles["icon"]} ${styles["twitter-icon"]}`}
								/>
							
						</div>
					</a>

					<a href="https://www.instagram.com/ellitoral/">
						<div
							className={`${styles["new-sidepanel-social-link"]} ${styles["color-instagram"]}`}
						>
							<Instagram
									className={`${styles["icon"]}`}
								/>
							
						</div>
					</a>

					<a href="https://youtube.com/ellitoralcom">
						<div
							className={`${styles["new-sidepanel-social-link"]} ${styles["color-youtube"]}`}
						>
								<YouTube
									className={`${styles["icon"]}`}
								/>
							
						</div>
					</a>

					<a href="https://api.whatsapp.com/send/?phone=543426310642&text&app_absent=0">
						<div
							className={`${styles["new-sidepanel-social-link"]} ${styles["color-whatsapp"]}`}
						>
								<Whatssap
									className={`${styles["icon"]}`}
								/>
							
						</div>
				    </a>
				</div>
			</nav>
		</div>
	);
};

export default SidePanel;

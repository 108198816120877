import Instagram from "./components/Instagram";
import Facebook from "./components/Facebook";
import Twitter from "./components/Twitter";
import Whatssap from "./components/Whatssap";
import YouTube from "./components/YouTube";
import Phone from "./components/Phone";
import Search from "./components/Search";
import Close from "./components/Close";
import ArrowLeft from "./components/ArrowLeft";
import ArrowRight from "./components/ArrowRight";
import ArrowsDouble from "./components/ArrowsDouble";
import AgainRedo from "./components/AgainRedo";
import List from "./components/List";

export { 
    Instagram, 
    Facebook, 
    Twitter, 
    Whatssap, 
    YouTube, 
    Phone,
    Search,
    Close,
    ArrowLeft,
    ArrowRight,
    ArrowsDouble,
    AgainRedo,
    List 
}